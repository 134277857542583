import React, { Fragment, MouseEvent } from 'react';
import { carryParams } from 'utils';

import { Logo } from 'assets/images';
import { useNavigate } from 'hooks/useNavigate';
import { useTranslation } from 'hooks/useTypedTranslation';
import { useAuthStore } from 'modules/auth/store/authStore';

import HeaderAvatar from '../dashboard/HeaderAvatar';
import Button from '../elements/Button';
import Head from '../elements/Head';
import Meta from '../elements/Meta';

type ErrorPageProps = {
  title: string;
  text: string;
  image: string;
};

export const ErrorPage: React.FC<ErrorPageProps> = ({ title, text, image }) => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const currentUser = useAuthStore(state => state.user);

  const handleBackClick = (event: MouseEvent) => {
    event.preventDefault();
    navigate(carryParams('/'));
  };

  return (
    <Fragment>
      <Head title={title} />
      <Meta />
      <div className="flex flex-col items-center justify-center min-h-screen bg-white text-center px-6">
        {/* Top Bar with Logo and User Avatar */}
        <div className="absolute top-6 left-6">
          <a href="/" onClick={handleBackClick}>
            <Logo className="h-6" />
          </a>
        </div>
        {currentUser && (
          <div className="absolute top-6 right-6">
            <HeaderAvatar isInstantListening={false} size={32} />
          </div>
        )}

        {/* Error Content */}
        <div className="flex flex-col items-center justify-center h-screen">
          <img src={image} alt={title} className="h-40 mb-6" />
          <h1 className="text-4xl font-semibold text-black">{title}</h1>
          <p className="text-base text-gray-600 mt-2">{text}</p>

          {/* Back Button */}
          <Button className="mt-6 px-6 py-3 bg-blue-600 text-white rounded-lg text-lg font-medium" onClick={handleBackClick}>
            {t('Return to My Library')}
          </Button>
        </div>

        {/* Footer Support Link */}
        <div className="absolute bottom-6 text-gray-500 text-sm">
          {t('Need help?')}{' '}
          <a href="https://help.speechify.com" className="text-blue-500 underline">
            {t('Contact Customer Support')}
          </a>
        </div>
      </div>
    </Fragment>
  );
};
